import React, { useEffect } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { useNavigate } from 'react-router-dom'

function App () {
  const navigate = useNavigate()
  const { isLoading, isAuthenticated } = useAuth0()

  useEffect(() => {
    if (!isLoading) {
      if (!isAuthenticated) {
        navigate('/login')
      } else {
        navigate('/transactions')
      }
    }
  }, [isLoading, isAuthenticated])

  return (
    <></>
  )
}

export default App
