import * as React from 'react'
import { Box, Button } from '@mui/material'
import { useAuth0 } from '@auth0/auth0-react'
import { useNavigate } from 'react-router-dom'
import { useEffect } from 'react'

function LoginPage () {
  const navigate = useNavigate()
  const { isAuthenticated } = useAuth0()
  const { loginWithRedirect } = useAuth0()

  useEffect(() => {
    if (isAuthenticated) {
      navigate('/')
    }
  });

  if (isAuthenticated) {
    return (
      <></>
    )
  } else {
    return (
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Button variant="contained" onClick={() => loginWithRedirect()}>Login</Button>
      </Box>
    )
  }
}

export default LoginPage
