import { BarChart } from '@mui/x-charts/BarChart'
import React, { useEffect, useState } from 'react'
import { axisClasses } from '@mui/x-charts'
import { BalancePerMonth, fetchBalancesPerMonth } from './chartsHandler'
import dayjs from 'dayjs'
import { useAuth0 } from '@auth0/auth0-react'
import { useNavigate } from 'react-router-dom'
import { Box, CircularProgress } from '@mui/material'

const chartSetting = {
  yAxis: [
    {},
  ],
  sx: {
    [`.${axisClasses.left} .${axisClasses.label}`]: {
      transform: 'translate(0px, 0)',
    },
  },
}

const valueFormatter = (value: number) => '€' + value.toLocaleString(navigator.language, { minimumFractionDigits: 2 })

type Props = {
  year: dayjs.Dayjs | null
}
const BalancesPerMonthChart = ({ year }: Readonly<Props>) => {
  const navigate = useNavigate()
  const { isLoading, isAuthenticated, getAccessTokenSilently } = useAuth0()
  const [balances, setBalances] = useState<BalancePerMonth[]>([])
  const [isFetching, setIsFetching] = useState(false)

  const fetchBalances = async () => {
    setIsFetching(true)
    const token = await getAccessTokenSilently()
    const currentDate = year ?? dayjs()
    const startDate = currentDate.startOf('year').format('YYYY-MM-DD')
    const endDate = currentDate.endOf('year').format('YYYY-MM-DD')
    const balances = await fetchBalancesPerMonth(token, startDate, endDate)
    setBalances(balances)
    setIsFetching(false)
  }

  useEffect(() => {
    if (!isLoading) {
      if (!isAuthenticated) {
        navigate('/login')
      } else {
        fetchBalances()
      }
    }
  }, [isLoading, isAuthenticated, getAccessTokenSilently, year])

  if (!isAuthenticated) {
    return (
      <></>
    )
  } else if (isFetching || balances.length === 0) {
    return (
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <CircularProgress/>
      </Box>
    )
  } else {
    return (
      <BarChart
        dataset={balances.map(value => {
          return {
            month: new Date(value.month).toLocaleDateString(navigator.language, {
              month: 'short',
            }),
            incomes: value.incomes / 100,
            expenses: value.expenses / 100
          }
        })}
        xAxis={[{ scaleType: 'band', dataKey: 'month' }]}
        series={[
          { dataKey: 'incomes', label: 'Incomes', valueFormatter, color: 'green' },
          { dataKey: 'expenses', label: 'Expenses', valueFormatter, color: 'red' },
        ]}
        {...chartSetting}
      />
    )
  }
}

export default BalancesPerMonthChart