import {
  AppBar, Box,
  CircularProgress,
  Container,
  IconButton,
  Slide,
  Toolbar,
  Typography,
  useScrollTrigger
} from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import React, { useEffect, useState } from 'react'
import { Params, useNavigate, useParams } from 'react-router-dom'
import BudgetDetails from './BudgetDetails'
import { BudgetPeriod, BudgetType, readBudget } from './budgetHandler'
import { useAuth0 } from '@auth0/auth0-react'
import dayjs from 'dayjs'

const BudgetDetailsPage = () => {
  const navigate = useNavigate()
  const { isLoading, isAuthenticated, getAccessTokenSilently } = useAuth0()
  const [budget, setBudget] = useState<BudgetType | null>(null)
  const [isFetchingBudget, setIsFetchingBudget] = useState(false)

  const { budgetId }: Readonly<Params<string>> = useParams()

  useEffect(() => {
    if (!isLoading) {
      if (!isAuthenticated) {
        navigate('/login')
      } else {
        const fetchBudget = async (budgetId: string) => {
          setIsFetchingBudget(true)
          const token = await getAccessTokenSilently()
          const budget = await readBudget(token, budgetId)
          setBudget(budget)
          setIsFetchingBudget(false)
        }

        if (budgetId && budgetId !== 'new') {
          fetchBudget(budgetId)
        } else {
          setBudget({
            id: 'new',
            period: BudgetPeriod.MONTHLY,
            category: 'Groceries',
            target: 0,
            date: dayjs().toString(),
            endDate: undefined
          })
        }
      }
    }
  }, [isLoading, isAuthenticated, getAccessTokenSilently])

  const trigger = useScrollTrigger()

  if (!isAuthenticated || !budget) {
    return (
      <></>
    )
  } else {
    return (
      <>
        <Slide appear={false} direction="down" in={!trigger}>
          <AppBar position="fixed">
            <Toolbar>
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                sx={{ mr: 2 }}
                onClick={() => navigate('/budgets')}
              >
                <ArrowBackIcon/>
              </IconButton>
              <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                Budget Details
              </Typography>
            </Toolbar>
          </AppBar>
        </Slide>
        <Toolbar/>
        <Container
          sx={{
            marginTop: 2,
            textAlign: 'center'
          }}
        >
          {isFetchingBudget ? (
            <Box
              sx={{
                marginTop: 8,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <CircularProgress/>
            </Box>
          ) : (
            <BudgetDetails
              budget={budget}
            />
          )
          }
        </Container>
      </>
    )
  }
}

export default BudgetDetailsPage