import { useLocation, useNavigate } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import React, { useEffect, useState } from 'react'
import {
  AppBar,
  Box,
  Button,
  CircularProgress,
  Container,
  Fab,
  Slide,
  Toolbar,
  Typography,
  useScrollTrigger
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import dayjs, { Dayjs } from 'dayjs'
import { readTransactions, TransactionType } from './transactionHandler'
import TransactionList from './TransactionList'
import NavigationBar from '../navigation/NavigationBar'

const TransactionsOverviewPage = () => {
  const location = useLocation()
  const monthParam = new URLSearchParams(location.search).get('month')
  const { isLoading, isAuthenticated, logout, getAccessTokenSilently } = useAuth0()
  const [month, setMonth] = useState<Dayjs | null>(
    monthParam ? dayjs(Date.parse(monthParam)) : dayjs(Date.now())
  )
  const [transactions, setTransactions] = useState<TransactionType[]>([])
  const [isFetchingTransactions, setIsFetchingTransactions] = useState(false)
  const navigate = useNavigate()

  const onLeft = () => {
    if (month) {
      setMonth(month?.subtract(1, 'month'))
    }
  }

  const onRight = () => {
    if (month) {
      setMonth(month?.add(1, 'month'))
    }
  }

  const fetchTransactions = async () => {
    setIsFetchingTransactions(true)
    const token = await getAccessTokenSilently()
    const currentDate = month ?? dayjs()
    const startDate = currentDate.startOf('month').format('YYYY-MM-DD')
    const endDate = currentDate.endOf('month').format('YYYY-MM-DD')
    const transactions = await readTransactions(token, startDate, endDate)
    setTransactions(transactions)
    setIsFetchingTransactions(false)
  }

  useEffect(() => {
    if (!isLoading) {
      if (!isAuthenticated) {
        navigate('/login')
      } else {
        fetchTransactions()
      }
    }
  }, [isLoading, isAuthenticated, getAccessTokenSilently, month])

  const balance = transactions.length > 0
    ? transactions.map(t => t.amount).reduce((t, s) => t + s)
    : 0

  const formatAmount = (amount: number) =>
    '€' + (amount / 100).toLocaleString(navigator.language, { minimumFractionDigits: 2 })

  const trigger = useScrollTrigger()

  if (!isAuthenticated) {
    return (
      <></>
    )
  } else {
    return (
      <>
        <Slide appear={false} direction="down" in={!trigger}>
          <AppBar position="fixed">
            <Toolbar>
              <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                Transactions
              </Typography>
              <Button
                color="inherit"
                onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}
              >Logout</Button>
            </Toolbar>
          </AppBar>
        </Slide>
        <Toolbar/>
        <Container
          sx={{
            marginTop: 2,
            marginBottom: 6,
            textAlign: 'center'
          }}
        >
          <Fab
            color="primary"
            aria-label="add"
            onClick={() => onLeft()}
          >
            <KeyboardArrowLeftIcon/>
          </Fab>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              views={['month', 'year']}
              value={month}
              onChange={(newValue) => setMonth(newValue)}
              sx={{
                marginLeft: 2,
                marginRight: 2
              }}
            />
          </LocalizationProvider>
          <Fab
            color="primary"
            aria-label="add"
            onClick={() => onRight()}
          >
            <KeyboardArrowRightIcon/>
          </Fab>
          {isFetchingTransactions ? (
            <Box
              sx={{
                marginTop: 8,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <CircularProgress/>
            </Box>
          ) : (
            <>
              <h2 style={{ color: balance > 0 ? 'green' : 'red' }}>{formatAmount(balance)}</h2>
              <TransactionList
                transactions={transactions}
              />
            </>
          )
          }
          <Fab
            sx={{
              position: 'fixed',
              bottom: 64,
              right: 16,
            }}
            color="primary"
            aria-label="add"
            onClick={() => navigate('/transactions/new')}
          >
            <AddIcon/>
          </Fab>
        </Container>
        <NavigationBar/>
      </>
    )
  }
}

export default TransactionsOverviewPage