import {
  AppBar,
  Box,
  Button,
  CircularProgress,
  Container,
  Fab,
  Slide,
  Toolbar,
  Typography,
  useScrollTrigger
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import NavigationBar from '../navigation/NavigationBar'
import AddIcon from '@mui/icons-material/Add'
import { useNavigate } from 'react-router-dom'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import dayjs, { Dayjs } from 'dayjs'
import { BudgetListType, readBudgets } from './budgetHandler'
import BudgetList from './BudgetList'

const BudgetsPage = () => {
  const monthParam = new URLSearchParams(location.search).get('month')
  const { isLoading, isAuthenticated, logout, getAccessTokenSilently } = useAuth0()
  const [month, setMonth] = useState<Dayjs | null>(
    monthParam ? dayjs(Date.parse(monthParam)) : dayjs(Date.now())
  )
  const [budgets, setBudgets] = useState<BudgetListType[]>([])
  const [isFetchingBudgets, setIsFetchingBudgets] = useState(false)
  const navigate = useNavigate()

  const onLeft = () => {
    if (month) {
      setMonth(month?.subtract(1, 'month'))
    }
  }

  const onRight = () => {
    if (month) {
      setMonth(month?.add(1, 'month'))
    }
  }

  const fetchBudgets = async () => {
    setIsFetchingBudgets(true)
    const token = await getAccessTokenSilently()
    const currentDate = month ?? dayjs()
    const startOfMonth = currentDate.startOf('month')
    const budgets = await readBudgets(token, startOfMonth.format('YYYY-MM-DD'))
    setBudgets(budgets)
    setIsFetchingBudgets(false)
  }

  useEffect(() => {
    if (!isLoading) {
      if (!isAuthenticated) {
        navigate('/login')
      } else {
        fetchBudgets()
      }
    }
  }, [isLoading, isAuthenticated, getAccessTokenSilently, month])

  const trigger = useScrollTrigger()

  return (
    <>
      <Slide appear={false} direction="down" in={!trigger}>
        <AppBar position="fixed">
          <Toolbar>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              Budgets
            </Typography>
            <Button
              color="inherit"
              onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}
            >Logout</Button>
          </Toolbar>
        </AppBar>
      </Slide>
      <Toolbar/>
      <Container
        sx={{
          marginTop: 2,
          marginBottom: 6,
          textAlign: 'center'
        }}
      >
        <Fab
          color="primary"
          aria-label="add"
          onClick={() => onLeft()}
        >
          <KeyboardArrowLeftIcon/>
        </Fab>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            views={['month', 'year']}
            value={month}
            onChange={(newValue) => setMonth(newValue)}
            sx={{
              marginLeft: 2,
              marginRight: 2
            }}
          />
        </LocalizationProvider>
        <Fab
          color="primary"
          aria-label="add"
          onClick={() => onRight()}
        >
          <KeyboardArrowRightIcon/>
        </Fab>
        {isFetchingBudgets ? (
          <Box
            sx={{
              marginTop: 8,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <CircularProgress/>
          </Box>
        ) : (
          <BudgetList
            budgets={budgets}
          />
        )}
        <Fab
          sx={{
            position: 'fixed',
            bottom: 64,
            right: 16,
          }}
          color="primary"
          aria-label="add"
          onClick={() => navigate('/budgets/new')}
        >
          <AddIcon/>
        </Fab>
      </Container>
      <NavigationBar/>
    </>
  )
}

export default BudgetsPage