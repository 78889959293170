import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import reportWebVitals from './reportWebVitals'
import App from './App'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import LoginPage from './LoginPage'
import { Auth0Provider } from '@auth0/auth0-react'
import TransactionDetailsPage from './transactions/TransactionDetailsPage'
import TransactionsOverviewPage from './transactions/TransactionsOverviewPage'
import ChartsPage from './charts/ChartsPage'
import { createTheme, ThemeProvider } from '@mui/material'
import BudgetsPage from './budgets/BudgetsPage'
import BudgetDetailsPage from './budgets/BudgetDetailsPage'

const router = createBrowserRouter([
  {
    path: '/',
    element: <App/>
  },
  {
    path: '/login',
    element: <LoginPage/>
  },
  {
    path: '/transactions',
    element: <TransactionsOverviewPage/>
  },
  {
    path: '/transactions/:transactionId',
    element: <TransactionDetailsPage/>
  },
  {
    path: '/charts',
    element: <ChartsPage/>
  },
  {
    path: '/budgets',
    element: <BudgetsPage/>
  },
  {
    path: '/budgets/:budgetId',
    element: <BudgetDetailsPage/>
  }
])

const theme = createTheme({
    palette: {
      mode: 'light',
      primary: {
        main: '#000000',
      },
      secondary: {
        main: '#f50057',
      },
    },
  }
)

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)
root.render(
  <Auth0Provider
    domain="dev-bcj81soqw6qu0b6z.eu.auth0.com"
    clientId="8yMgtSw0qg6Igx1xF9HQRw7OVZm2BxhB"
    authorizationParams={{
      redirect_uri: window.location.origin + '/login',
      audience: 'https://budget.digitalmindshift.de/api'
    }}
  >
    <React.StrictMode>
      <ThemeProvider theme={theme}>
        <RouterProvider router={router}/>
      </ThemeProvider>
    </React.StrictMode>
  </Auth0Provider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
