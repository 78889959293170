const baseUrl = process.env.REACT_APP_API_ENDPOINT + '/charts'

export interface BalancePerMonth {
  month: string;
  incomes: number;
  expenses: number;
}

export async function fetchBalancesPerMonth (token: string, startDate: string, endDate: string): Promise<BalancePerMonth[]> {
  return fetch(
    baseUrl + '/balances-per-month' + '?' + new URLSearchParams({ startDate: startDate, endDate: endDate }),
    {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
      }
    }
  )
    .then(res => res.json())
    .then(data => data as BalancePerMonth[])
}