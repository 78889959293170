import { List, ListItem, ListItemText, ListSubheader } from '@mui/material'
import React from 'react'
import { TransactionType } from './transactionHandler'
import { useNavigate } from 'react-router-dom'

type Props = {
  transactions: TransactionType[]
}

function TransactionList ({ transactions }: Readonly<Props>) {
  const navigate = useNavigate()

  const getTransactionsContent = (transactions: TransactionType[]) => {
    const dates = new Set<string>()
    const content = []
    for (const transaction of transactions) {
      if (!dates.has(transaction.date)) {
        content.push(
          <ListSubheader
            key={'subheader_' + transaction.date}
          >
            {new Date(transaction.date).toLocaleDateString(navigator.language, {
              day: '2-digit',
              month: '2-digit',
            })}
          </ListSubheader>
        )
        dates.add(transaction.date)
      }

      content.push(
        <ListItem
          key={transaction.id}
          secondaryAction={
            <ListItemText
              sx={{ color: transaction.amount > 0 ? 'green' : 'red' }}
            >
              <b>{'€' + (transaction.amount / 100).toLocaleString(navigator.language, { minimumFractionDigits: 2 })}</b>
            </ListItemText>
          }
          onClick={() => navigate('/transactions/' + transaction.id)}
        >
          <ListItemText primary={transaction.category}
                        secondary={transaction.description + (transaction.interval ? ' (r)' : '')}/>
        </ListItem>
      )
    }

    return content
  }

  return (
    <List>
      {getTransactionsContent(transactions)}
    </List>
  )
}

export default TransactionList