const baseUrl = process.env.REACT_APP_API_ENDPOINT + '/transactions'

export enum TransactionInterval {
  MONTHLY = 'MONTHLY'
}

export type CreateTransactionType = {
  category: string;
  description: string;
  amount: number;
  date: string;
  interval: TransactionInterval | undefined;
  endDate: string | undefined;
}

export type UpdateTransactionType = {
  category: string;
  description: string;
  amount: number;
  date: string;
  interval: TransactionInterval | undefined;
  endDate: string | undefined;
}

export type TransactionType = {
  id: string;
  date: string;
  category: string;
  description: string;
  amount: number;
  interval: TransactionInterval | undefined;
  endDate: string | undefined;
}

export const categories = [
  'Bike',
  'Bus and Train',
  'Car',
  'Cash and Misc',
  'Childcare',
  'Clothing',
  'Clothing Kids',
  'Diapers and Wipes',
  'Dining Out and Ordering',
  'Doctors and Medication',
  'Energy',
  'Furniture and Furnishings',
  'GEZ',
  'Gifts',
  'Groceries',
  'Heating',
  'Insurances',
  'Internet and Services',
  'Operating Costs',
  'Rent',
  'Sports and Leisure',
  'Tools and Equipment',
  'Toys',
  'Travel',
  'Wages',
  'Water',
  'Wedding'
]

export async function createTransaction (token: string, values: CreateTransactionType): Promise<TransactionType> {
  return fetch(
    baseUrl,
    {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(values)
    }
  )
    .then(res => res.json())
    .then(data => data as TransactionType)
}

export async function readTransactions (token: string, startDate: string, endDate: string): Promise<TransactionType[]> {
  return fetch(
    baseUrl + '?' + new URLSearchParams({ startDate: startDate, endDate: endDate }),
    {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
      }
    }
  )
    .then(res => res.json())
    .then(data => data as TransactionType[])
}

export async function readTransaction (token: string, id: string): Promise<TransactionType> {
  return fetch(
    baseUrl + '/' + id,
    {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
      }
    }
  )
    .then(res => res.json())
    .then(data => data as TransactionType)
}

export async function updateTransaction (token: string, id: string, values: UpdateTransactionType): Promise<TransactionType> {
  return fetch(
    baseUrl + '/' + id,
    {
      method: 'PUT',
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(values)
    }
  )
    .then(res => res.json())
    .then(data => data as TransactionType)
}

export async function deleteTransaction (token: string, id: string): Promise<void> {
  fetch(
    baseUrl + '/' + id,
    {
      method: 'DELETE',
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
      }
    }
  )
}