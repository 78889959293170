const baseUrl = process.env.REACT_APP_API_ENDPOINT + '/budgets'

export enum BudgetPeriod {
  MONTHLY = 'MONTHLY',
  YEARLY = 'YEARLY',
}

export type CreateBudgetType = {
  period: BudgetPeriod;
  category: string;
  target: number;
  date: string;
  endDate: string | undefined;
}

export type UpdateBudgetType = {
  period: BudgetPeriod;
  category: string;
  target: number;
  date: string;
  endDate: string | undefined;
}

export type BudgetType = {
  id: string;
  period: BudgetPeriod;
  category: string;
  target: number;
  date: string;
  endDate: string | undefined;
}

export type BudgetListType = {
  id: string;
  period: BudgetPeriod;
  category: string;
  current: number;
  target: number;
}

export async function createBudget (token: string, values: CreateBudgetType): Promise<BudgetType> {
  return fetch(
    baseUrl,
    {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(values)
    }
  )
    .then(res => res.json())
    .then(data => data as BudgetType)
}

export async function readBudgets (token: string, date: string): Promise<BudgetListType[]> {
  return fetch(
    baseUrl + '?' + new URLSearchParams({ date: date }),
    {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
      }
    }
  )
    .then(res => res.json())
    .then(data => data as BudgetListType[])
}

export async function readBudget (token: string, id: string): Promise<BudgetType> {
  return fetch(
    baseUrl + '/' + id,
    {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
      }
    }
  )
    .then(res => res.json())
    .then(data => data as BudgetType)
}

export async function updateBudget (token: string, id: string, values: UpdateBudgetType): Promise<BudgetType> {
  return fetch(
    baseUrl + '/' + id,
    {
      method: 'PUT',
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(values)
    }
  )
    .then(res => res.json())
    .then(data => data as BudgetType)
}

export async function deleteBudget (token: string, id: string): Promise<void> {
  fetch(
    baseUrl + '/' + id,
    {
      method: 'DELETE',
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
      }
    }
  )
}