import { BottomNavigation, BottomNavigationAction, Paper } from '@mui/material'
import TocIcon from '@mui/icons-material/Toc'
import BarChartIcon from '@mui/icons-material/BarChart'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { MonetizationOn } from '@mui/icons-material'

const NavigationBar = () => {
  const navigate = useNavigate()

  return (
    <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, zIndex: 1100 }} elevation={3}>
      <BottomNavigation
        showLabels
        value={0}
      >
        <BottomNavigationAction label="Transactions" icon={<TocIcon/>} onClick={() => navigate('/transactions')}/>
        <BottomNavigationAction label="Charts" icon={<BarChartIcon/>} onClick={() => navigate('/charts')}/>
        <BottomNavigationAction label="Budgets" icon={<MonetizationOn/>} onClick={() => navigate('/budgets')}/>
      </BottomNavigation>
    </Paper>
  )
}

export default NavigationBar