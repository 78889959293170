import {
  Box,
  LinearProgress,
  LinearProgressProps,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Stack,
  Typography
} from '@mui/material'
import { BudgetListType } from './budgetHandler'
import React from 'react'
import { useNavigate } from 'react-router-dom'

type Props = {
  budgets: BudgetListType[]
}

function LinearProgressWithLabel (props: LinearProgressProps & { value: number }) {
  const color = props.value > 100 ? 'error' : 'success'
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant="determinate" {...props} color={color}/>
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color={color}>{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box>
    </Box>
  )
}

const formatAmount = (amount: number) =>
  '€' + (amount / 100).toLocaleString(navigator.language, { minimumFractionDigits: 2 })

function BudgetList ({ budgets }: Readonly<Props>) {
  const navigate = useNavigate()

  const getBudgetsContent = (budgets: BudgetListType[]) => {
    let currentPeriod = undefined
    const content = []
    for (const budget of budgets) {
      if (budget.period !== currentPeriod) {
        content.push(
          <ListSubheader
            key={'subheader_' + budget.period}
          >
            {budget.period}
          </ListSubheader>
        )
        currentPeriod = budget.period
      }

      const color = budget.current / budget.target > 1 ? 'error' : 'success'
      content.push(
        <ListItem
          key={budget.id}
          onClick={() => navigate('/budgets/' + budget.id)}
        >
          <ListItemText primary={budget.category} secondary={formatAmount(budget.target)}/>
          <Stack spacing={1}>
            <Typography sx={{ textAlign: 'center' }} variant="body1"
                        color={color}>{formatAmount(budget.current)}</Typography>
            <LinearProgressWithLabel value={budget.current / budget.target * 100} sx={{ minWidth: 150 }}/>
          </Stack>
        </ListItem>
      )
    }

    return content
  }

  return (
    <List>
      {getBudgetsContent(budgets)}
    </List>
  )
}

export default BudgetList