import { AppBar, Button, Container, Fab, Slide, Toolbar, Typography, useScrollTrigger } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import dayjs, { Dayjs } from 'dayjs'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import BalancesPerMonthChart from './BalancesPerMonthChart'
import NavigationBar from '../navigation/NavigationBar'

const ChartsPage = () => {
  const navigate = useNavigate()
  const { isLoading, isAuthenticated, logout, getAccessTokenSilently } = useAuth0()
  const [year, setYear] = useState<Dayjs | null>(dayjs(Date.now()))

  useEffect(() => {
    if (!isLoading) {
      if (!isAuthenticated) {
        navigate('/login')
      }
    }
  }, [isLoading, isAuthenticated, getAccessTokenSilently, year])

  const trigger = useScrollTrigger()

  if (!isAuthenticated) {
    return (
      <></>
    )
  } else {
    return (
      <>
        <Slide appear={false} direction="down" in={!trigger}>
          <AppBar position="fixed">
            <Toolbar>
              <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                Charts
              </Typography>
              <Button
                color="inherit"
                onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}
              >Logout</Button>
            </Toolbar>
          </AppBar>
        </Slide>
        <Toolbar/>
        <Container
          sx={{
            marginTop: 2,
            marginBottom: 6,
            width: '100vw',
            height: '50vh',
            textAlign: 'center'
          }}
        >
          <Fab
            color="primary"
            aria-label="add"
            onClick={() => setYear(year?.subtract(1, 'year') ?? dayjs())}
          >
            <KeyboardArrowLeftIcon/>
          </Fab>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              views={['year']}
              value={year}
              onChange={(newValue) => setYear(newValue)}
              sx={{
                marginLeft: 2,
                marginRight: 2
              }}
            />
          </LocalizationProvider>
          <Fab
            color="primary"
            aria-label="add"
            onClick={() => setYear(year?.add(1, 'year') ?? dayjs())}
          >
            <KeyboardArrowRightIcon/>
          </Fab>
          <BalancesPerMonthChart
            year={year}
          />
        </Container>
        <NavigationBar/>
      </>
    )
  }
}

export default ChartsPage