import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  ToggleButton,
  ToggleButtonGroup
} from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import React, { useState } from 'react'
import dayjs, { Dayjs } from 'dayjs'
import { NumericFormat, NumericFormatProps } from 'react-number-format'
import { useNavigate } from 'react-router-dom'
import {
  categories,
  createTransaction,
  deleteTransaction,
  TransactionInterval,
  TransactionType,
  updateTransaction
} from './transactionHandler'
import { useAuth0 } from '@auth0/auth0-react'
import 'dayjs/locale/de'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

export const NumericFormatCustom = React.forwardRef<NumericFormatProps, CustomProps>(
  function NumericFormatCustom (props, ref) {
    const { onChange, ...other } = props

    return (
      <NumericFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          })
        }}
        thousandSeparator="."
        decimalSeparator=","
        decimalScale={2}
        valueIsNumericString
        prefix="€"
      />
    )
  },
)

type Props = {
  transaction: TransactionType
}

const TransactionDetails = ({ transaction }: Props) => {
  const navigate = useNavigate()
  const { getAccessTokenSilently } = useAuth0()

  const [transactionType, setTransactionType] = React.useState(transaction.amount <= 0 ? 'expense' : 'income')
  const [date, setDate] = useState<Dayjs | null>(dayjs(transaction.date))
  const [category, setCategory] = useState(transaction.category)
  const [description, setDescription] = useState(transaction.description)
  const [amount, setAmount] = useState(transaction.amount >= 0 ? transaction.amount / 100 : transaction.amount / -100)
  const [recurring, setRecurring] = useState(transaction.interval && true)
  const [interval, setInterval] = useState(transaction.interval)
  const [endDate, setEndDate] = useState<Dayjs | undefined>(transaction.endDate ? dayjs(transaction.endDate) : undefined)
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = React.useState(false)

  const onCreate = async () => {
    const token = await getAccessTokenSilently()
    const transactionDate = date ?? dayjs()
    await createTransaction(token, {
      category: category,
      description: description,
      amount: Math.round(amount * 100.0) * (transactionType === 'expense' ? -1 : 1),
      date: transactionDate.format('YYYY-MM-DD'),
      interval: interval,
      endDate: endDate?.format('YYYY-MM-DD')
    })
    sessionStorage.setItem('transaction-date', transactionDate.format('YYYY-MM-DD'))
    navigate(`/transactions?month=${transactionDate.format('YYYY-MM')}`)
  }

  const onUpdate = async () => {
    const token = await getAccessTokenSilently()
    const transactionDate = date ?? dayjs()
    await updateTransaction(
      token,
      transaction.id,
      {
        category: category,
        description: description,
        amount: Math.round(amount * 100.0) * (transactionType === 'expense' ? -1 : 1),
        date: transactionDate.format('YYYY-MM-DD'),
        interval: interval,
        endDate: endDate?.format('YYYY-MM-DD')
      }
    )
    sessionStorage.setItem('transaction-date', transactionDate.format('YYYY-MM-DD'))
    navigate(`/transactions?month=${transactionDate.format('YYYY-MM')}`)
  }

  const onDelete = async () => {
    const token = await getAccessTokenSilently()
    await deleteTransaction(
      token,
      transaction.id,
    )
    const transactionDate = date ?? dayjs()
    navigate(`/transactions?month=${transactionDate.format('YYYY-MM')}`)
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <TextField
          id="description"
          name="description"
          label="Description"
          fullWidth
          variant="standard"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth>
          <InputLabel id="category-label">Category</InputLabel>
          <Select
            labelId="category-label"
            id="category"
            value={category}
            onChange={(e) => setCategory(e.target.value as string)}
            label="Category"
          >
            {categories.map((category) => (
              <MenuItem key={category} value={category}>{category}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={4}>
        <ToggleButtonGroup
          color="primary"
          value={transactionType}
          exclusive
          onChange={(e, v) => setTransactionType(v)}
          aria-label="Platform"
          fullWidth
        >
          <ToggleButton value="income"><AddIcon/></ToggleButton>
          <ToggleButton value="expense"><RemoveIcon/></ToggleButton>
        </ToggleButtonGroup>
      </Grid>
      <Grid item xs={8}>
        <TextField
          id="amount"
          name="amount"
          label="Amount"
          fullWidth
          variant="standard"
          value={amount}
          onChange={(e) => setAmount(Number(e.target.value))}
          InputProps={{
            /* eslint-disable  @typescript-eslint/no-explicit-any */
            inputComponent: NumericFormatCustom as any,
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth>
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="de">
            <DatePicker
              label="Date"
              value={date}
              onChange={(newValue) => setDate(newValue)}
            />
          </LocalizationProvider>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={recurring}
                onChange={
                  (e) => {
                    setRecurring(e.target.checked)
                    setInterval(e.target.checked ? TransactionInterval.MONTHLY : undefined)
                    setEndDate(undefined)
                  }
                }
              />
            }
            label="Recurring Transaction"
          />
        </FormGroup>
      </Grid>
      {recurring ?
        <>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel id="interval-label">Interval</InputLabel>
              <Select
                labelId="interval-label"
                id="interval"
                value={interval}
                onChange={(e) => setInterval(e.target.value as TransactionInterval)}
                label="Interval"
              >
                <MenuItem value={TransactionInterval.MONTHLY}>monthly</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="de">
                <DatePicker
                  label="End date"
                  value={endDate}
                  onChange={(newValue) => setEndDate(newValue !== null ? newValue : undefined)}
                />
              </LocalizationProvider>
            </FormControl>
          </Grid>
        </> :
        <></>
      }
      {transaction.id !== 'new' ? (
        <>
          <Grid item xs={6}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Button
                variant="contained"
                onClick={() => onUpdate()}
              >Update</Button>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Button
                variant="contained"
                onClick={() => setDeleteConfirmationOpen(true)}
              >Delete</Button>
              <Dialog
                open={deleteConfirmationOpen}
                onClose={() => setDeleteConfirmationOpen(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">
                  {'Delete transaction?'}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    Do you really want to delete this transaction?
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => setDeleteConfirmationOpen(false)}>Cancel</Button>
                  <Button onClick={() => onDelete()} autoFocus>
                    Yes
                  </Button>
                </DialogActions>
              </Dialog>
            </Box>
          </Grid>
        </>
      ) : (
        <Grid item xs={12}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Button
              variant="contained"
              onClick={() => onCreate()}
            >Create</Button>
          </Box>
        </Grid>
      )
      }
    </Grid>
  )
}

export default TransactionDetails