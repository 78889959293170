import React, { useEffect, useState } from 'react'
import { Params, useNavigate, useParams } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import { readTransaction, TransactionType } from './transactionHandler'
import TransactionDetails from './TransactionDetails'
import dayjs from 'dayjs'
import {
  AppBar,
  Box,
  CircularProgress,
  Container,
  IconButton,
  Slide,
  Toolbar,
  Typography,
  useScrollTrigger
} from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'

const TransactionDetailsPage = () => {
  const navigate = useNavigate()
  const { isLoading, isAuthenticated, getAccessTokenSilently } = useAuth0()
  const [transaction, setTransaction] = useState<TransactionType | null>(null)
  const [isFetchingTransaction, setIsFetchingTransaction] = useState(false)

  const { transactionId }: Readonly<Params<string>> = useParams()

  useEffect(() => {
    if (!isLoading) {
      if (!isAuthenticated) {
        navigate('/login')
      } else {
        const fetchTransaction = async (transactionId: string) => {
          setIsFetchingTransaction(true)
          const token = await getAccessTokenSilently()
          const transaction = await readTransaction(token, transactionId)
          setTransaction(transaction)
          setIsFetchingTransaction(false)
        }

        if (transactionId && transactionId !== 'new') {
          fetchTransaction(transactionId)
        } else {
          const transactionDate = sessionStorage.getItem('transaction-date')
          setTransaction({
            id: 'new',
            date: transactionDate ? dayjs(Date.parse(transactionDate)).toString() : dayjs().toString(),
            category: 'Groceries',
            description: '',
            amount: 0,
            interval: undefined,
            endDate: undefined
          })
        }
      }
    }
  }, [isLoading, isAuthenticated, getAccessTokenSilently])

  const trigger = useScrollTrigger()

  if (!isAuthenticated || !transaction) {
    return (
      <></>
    )
  } else {
    return (
      <>
        <Slide appear={false} direction="down" in={!trigger}>
          <AppBar position="fixed">
            <Toolbar>
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                sx={{ mr: 2 }}
                onClick={() => navigate('/')}
              >
                <ArrowBackIcon/>
              </IconButton>
              <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                Transaction Details
              </Typography>
            </Toolbar>
          </AppBar>
        </Slide>
        <Toolbar/>
        <Container
          sx={{
            marginTop: 2,
            textAlign: 'center'
          }}
        >
          {isFetchingTransaction ? (
            <Box
              sx={{
                marginTop: 8,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <CircularProgress/>
            </Box>
          ) : (
            <TransactionDetails
              transaction={transaction}/>
          )
          }
        </Container>
      </>
    )
  }
}

export default TransactionDetailsPage