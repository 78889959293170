import { BudgetPeriod, BudgetType, createBudget, deleteBudget, updateBudget } from './budgetHandler'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from '@mui/material'
import React, { useState } from 'react'
import { categories } from '../transactions/transactionHandler'
import { NumericFormatCustom } from '../transactions/TransactionDetails'
import { useNavigate } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import dayjs, { Dayjs } from 'dayjs'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

type Props = {
  budget: BudgetType
}

const BudgetDetails = ({ budget }: Props) => {
  const navigate = useNavigate()
  const { getAccessTokenSilently } = useAuth0()

  const [period, setPeriod] = useState(budget.period)
  const [category, setCategory] = useState(budget.category)
  const [target, setTarget] = useState(budget.target / 100)
  const [date, setDate] = useState<Dayjs | null>(dayjs(budget.date))
  const [endDate, setEndDate] = useState<Dayjs | undefined>(budget.endDate ? dayjs(budget.endDate) : undefined)
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = React.useState(false)

  const onCreate = async () => {
    const token = await getAccessTokenSilently()
    const budgetDate = date ?? dayjs()
    await createBudget(token, {
      period: period,
      category: category,
      target: Math.round(target * 100.0),
      date: budgetDate.startOf('month').format('YYYY-MM-DD'),
      endDate: endDate?.startOf('month').format('YYYY-MM-DD')
    })
    navigate('/budgets')
  }

  const onUpdate = async () => {
    const token = await getAccessTokenSilently()
    const budgetDate = date ?? dayjs()
    await updateBudget(
      token,
      budget.id,
      {
        period: period,
        category: category,
        target: Math.round(target * 100.0),
        date: budgetDate.startOf('month').format('YYYY-MM-DD'),
        endDate: endDate?.startOf('month').format('YYYY-MM-DD')
      }
    )
    navigate('/budgets')
  }

  const onDelete = async () => {
    const token = await getAccessTokenSilently()
    await deleteBudget(
      token,
      budget.id
    )
    navigate('/budgets')
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <FormControl fullWidth>
          <InputLabel id="category-label">Period</InputLabel>
          <Select
            labelId="period-label"
            id="period"
            value={period}
            onChange={(e) => setPeriod(e.target.value as BudgetPeriod)}
            label="Category"
          >
            {Object.values(BudgetPeriod).map((category) => (
              <MenuItem key={category} value={category}>{category}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth>
          <InputLabel id="category-label">Category</InputLabel>
          <Select
            labelId="category-label"
            id="category"
            value={category}
            onChange={(e) => setCategory(e.target.value as string)}
            label="Category"
          >
            {categories.map((category) => (
              <MenuItem key={category} value={category}>{category}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <TextField
          id="target"
          name="target"
          label="Target"
          fullWidth
          variant="standard"
          value={target}
          onChange={(e) => setTarget(Number(e.target.value))}
          InputProps={{
            /* eslint-disable  @typescript-eslint/no-explicit-any */
            inputComponent: NumericFormatCustom as any,
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth>
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="de">
            <DatePicker
              label="Date"
              views={['month', 'year']}
              value={date}
              onChange={(newValue) => setDate(newValue)}
            />
          </LocalizationProvider>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth>
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="de">
            <DatePicker
              label="End date"
              views={['month', 'year']}
              value={endDate}
              onChange={(newValue) => setEndDate(newValue !== null ? newValue : undefined)}
            />
          </LocalizationProvider>
        </FormControl>
      </Grid>
      {budget.id !== 'new' ? (
        <>
          <Grid item xs={6}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Button
                variant="contained"
                onClick={() => onUpdate()}
              >Update</Button>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Button
                variant="contained"
                onClick={() => setDeleteConfirmationOpen(true)}
              >Delete</Button>
              <Dialog
                open={deleteConfirmationOpen}
                onClose={() => setDeleteConfirmationOpen(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">
                  {'Delete budget?'}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    Do you really want to delete this budget?
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => setDeleteConfirmationOpen(false)}>Cancel</Button>
                  <Button onClick={() => onDelete()} autoFocus>
                    Yes
                  </Button>
                </DialogActions>
              </Dialog>
            </Box>
          </Grid>
        </>
      ) : (
        <Grid item xs={12}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Button
              variant="contained"
              onClick={() => onCreate()}
            >Create</Button>
          </Box>
        </Grid>
      )
      }
    </Grid>
  )
}

export default BudgetDetails